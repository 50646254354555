var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"aware",rawName:"v-aware",value:({
    appear: {
      threshold: [0.5, 0.75, 1],
      once: true,
    },
  }),expression:"{\n    appear: {\n      threshold: [0.5, 0.75, 1],\n      once: true,\n    },\n  }"}],ref:"rootElRef",staticClass:"flexible-before-after",class:{ 'is-dragging': _vm.isDraggingRef },style:(("--slider-progress: " + _vm.progressRef + "%;")),on:{"viewport":_vm.onResize,"appear":_vm.onAppear}},[_c('h2',{staticClass:"flexible-before-after__title h3--bold mb-l wrapper",domProps:{"innerHTML":_vm._s(_vm.content.title)}}),_c('div',{ref:"innerElRef",staticClass:"flexible-before-after-inner wrapper"},[_c('ul',{staticClass:"flexible-before-after__slider",attrs:{"tag":"ul","mode":"out-in"}},_vm._l((_vm.content.items),function(item,i){return _c('li',{key:i,ref:"itemsElRef",refInFor:true,staticClass:"flexible-before-after__slider__item",class:{
          'is-portrait': _vm.isPortrait(item.pictureBefore),
          'is-visible': _vm.indexRef === i,
        }},[_c('div',{staticClass:"flexible-before-after__slider__item-inner mb-s"},[_c('UiPicture',{key:(_vm.indexRef + "-before"),staticClass:"flexible-before-after__slider__item__picture br-small",attrs:{"content":_vm.content.items[_vm.indexRef].pictureBefore,"sets":['160', '240', '320', '480', '640', '960', '1280', '1440'],"cover":true},scopedSlots:_vm._u([{key:"caption",fn:function(){return [_c('figcaption',{staticClass:"flexible-before-after__slider__item__label g-label"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"icon",attrs:{"symbol":"ui-caret","size":"0 0 36 36"}}),_vm._v(" "+_vm._s(_vm.content.labels.before)+" ")])]},proxy:true}],null,true)}),_c('UiPicture',{key:(_vm.indexRef + "-after"),staticClass:"flexible-before-after__slider__item__picture--after br-small",attrs:{"content":_vm.content.items[_vm.indexRef].pictureAfter,"sets":['160', '240', '320', '480', '640', '960', '1280', '1440'],"cover":true},scopedSlots:_vm._u([{key:"caption",fn:function(){return [_c('figcaption',{staticClass:"flexible-before-after__slider__item__label--after g-label"},[_vm._v(" "+_vm._s(_vm.content.labels.after)+" "),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"icon",attrs:{"symbol":"ui-caret","size":"0 0 36 36"}})])]},proxy:true}],null,true)})],1),(item.htmltext)?_c('div',{staticClass:"flexible-before-after__slider__item__caption pb-s small"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"symbol":"ui-arrow-small-border","size":"0 0 24 24"}}),_c('div',{domProps:{"innerHTML":_vm._s(item.htmltext)}})]):_vm._e()])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButtonRef),expression:"showButtonRef"}],staticClass:"flexible-before-after__slider__button-outer",class:{
        'is-portrait': _vm.isPortrait(_vm.content.items[_vm.indexRef].pictureBefore),
      }},[_c('button',{ref:"buttonElRef",staticClass:"flexible-before-after__slider__button",attrs:{"disabled":!_vm.showButtonRef},on:{"mousedown":_vm.onDragStart,"touchstart":_vm.onDragStart,"focus":_vm.onButtonFocus,"blur":_vm.onButtonBlur}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"icon",attrs:{"symbol":"ui-caret","size":"0 0 30 30"}}),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"icon",attrs:{"symbol":"ui-caret","size":"0 0 30 30"}})])])]),(_vm.content.items.length > 1)?_c('div',{staticClass:"flexible-before-after__nav"},[_c('div',{staticClass:"flexible-before-after__nav-inner"},[_c('ul',{staticClass:"flexible-before-after__nav__items"},_vm._l((_vm.content.items),function(item,index){return _c('li',{key:item.pictureAfter.src,staticClass:"flexible-before-after__nav__item-outer"},[_c('button',{staticClass:"flexible-before-after__nav__item",attrs:{"aria-selected":_vm.indexRef === index},on:{"click":function($event){return _vm.onNavClick(index)}}},[_c('div',{staticClass:"flexible-before-after__nav__item__label",domProps:{"innerHTML":_vm._s(item.htmltext)}}),_c('figure',{staticClass:"flexible-before-after__nav__item__picture"},[_c('img',{directives:[{name:"src",rawName:"v-src",value:(item.pictureAfter),expression:"item.pictureAfter"}],attrs:{"sets":['160', '240', '320'],"alt":item.pictureAfter.alt,"loading":"lazy"}})])])])}),0)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }